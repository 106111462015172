import { useLocation } from "react-router-dom";

const Error = ({ type }) => {
  const location = useLocation();
  return (
    <>{type === 1 ? <div>모바일 기기로는 접속하실 수 없습니다.</div> : ""}</>
  );
};

export default Error;
