import { lazy } from "react";
import { useRoutes } from "react-router-dom";

const Layout = lazy(() => import("../components/Layout"));
// LOGIN
const Login = lazy(() => import("../pages/Login"));
// REGISTRATION
const Registration = lazy(() => import("../pages/Registration"));
// FIND PASSWORD
const FindPassword = lazy(() => import("../pages/FindPassword"));
// DashBoard
const DashBoard = lazy(() => import("../pages/DashBoard"));

const Router = () =>
  useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Login />,
        },
        {
          path: "/registration",
          element: <Registration />,
        },
        {
          path: "/find-password",
          element: <FindPassword />,
        },
        {
          path: "/dashboard",
          element: <DashBoard />,
          children: [],
        },
      ],
    },
  ]);

export default Router;
