import { Suspense } from "react";
import { isMobile } from "react-device-detect";
import Router from "./router";

import Error from "./pages/Error";

function App() {
  return (
    <>
      {!isMobile ? (
        <Suspense>
          <Router />
        </Suspense>
      ) : (
        <Error type={1} />
      )}
    </>
  );
}

export default App;
